<template>
  <div>
    <div class="nav-wrapper">
      <div v-if="showBatchOperation" class="left">
        <el-button
          v-if="$auth('acadSaas.dService.vBuy.bulkBuy')"
          class="reset-btn"
          type="primary"
          plain
          @click="onBatchPurchase"
        >
          批量采购
        </el-button>
      </div>
      <div v-if="!showBatchOperation" class="left">
        <el-input
          v-model="searchParams.title"
          v-debounce="[onSearch, 'input']"
          class="input"
          clearable
          prefix-icon="el-icon-search"
          placeholder="请输入委托服务包名称"
          @clear="onSearch"
          @keydown.enter.native="onSearch"
        />
        <el-select
          v-model="searchParams.isQueryChild"
          class="select"
          placeholder="所有创建者"
          clearable
          @change="onSearch"
        >
          <el-option
            v-for="item in createrList"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          />
        </el-select>
        <el-date-picker
          :value="[searchParams.startAt, searchParams.endAt]"
          :disabled="isExport"
          type="daterange"
          range-separator="—"
          format="yyyy年MM月dd日"
          unlink-panels
          :default-time="['00:00:00', '23:59:59']"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          :editable="false"
          @change="onChangeDate"
        />
        <el-radio-group v-model="activeRadio" @change="onChangeRadio">
          <el-radio-button v-for="item in radioList" :key="item.key" :label="item.key">
            {{ item.name }}
          </el-radio-button>
        </el-radio-group>
      </div>
      <div class="right">
        <el-row class="action-fix">
          <el-button
            v-if="showOperation"
            class="reset-btn"
            type="primary"
            plain
            @click="onBatchOperation"
          >
            {{ !showBatchOperation ? '批量操作' : '取消批量操作' }}
          </el-button>
          <el-button
            v-if="!isMobile && $auth('acadSaas.dService.vBuy.exportData')"
            class="reset-btn"
            type="primary"
            icon="el-icon-plus"
            @click="onExport"
          >
            下载数据
          </el-button>
        </el-row>
      </div>
    </div>
    <el-table
      v-loading="loading"
      :data="dataList"
      stripe
      style="width: 100%;"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="showBatchOperation"
        type="selection"
        width="55"
        align="center"
      />
      <el-table-column
        label="委托服务包名称"
        width="200"
        prop="delegationName"
        fixed
      />
      <el-table-column
        label="类型"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.sceneType === 5">直播</span>
          <span v-else-if="scope.row.sceneType === 6">会议</span>
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        align="center"
        prop="servicePackageStatus"
      >
        审核成功待确认
      </el-table-column>
      <el-table-column
        label="场次"
        align="center"
        prop="number"
      />
      <el-table-column
        label="金额"
        align="center"
        prop="amount"
      />
      <el-table-column
        label="审核通过时间"
        align="center"
        width="200"
        prop="reviewTime"
      />
      <el-table-column
        label="创建者"
        align="center"
        width="200"
        prop="orgName"
      />
      <el-table-column
        v-if="showOperation"
        label="操作"
        align="center"
        width="200"
        :fixed="!isMobile ? 'right' : false"
      >
        <template v-if="$auth('acadSaas.dService.vBuy.bulkBuy')" slot-scope="scope">
          <el-button
            type="text"
            size="small"
            :loading="isPadding"
            @click="onOperate(scope.row.delegationId, '1')"
          >
            确认采购
          </el-button>
          <el-button
            type="text"
            size="small"
            style="color: #e8312f;"
            :loading="isPadding"
            @click="onOperate(scope.row.delegationId, '0')"
          >
            拒绝采购
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 20px;"
      class="pagination"
      hide-on-single-page
      :page-size="pagination.pageSize"
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-sizes="pagination.sizes"
      :layout="pagination.layout"
      :small="pagination.small"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />

    <el-dialog title="委托项目预算不足" :visible.sync="showProjectPayResultList" class="customize-el-dialog">
      <el-table
        :data="projectPayResultList"
        class="customize-el-table"
        style="width: 100%;"
        max-height="400px"
      >
        <el-table-column
          prop="projectName"
          label="项目名称"
          width="180"
        />
        <el-table-column
          prop="targetOrgName"
          label="执行组织"
          width="180"
        />
        <el-table-column
          prop="remainBudget"
          label="剩余预算"
        />
        <el-table-column
          prop="deduceAmount"
          label="扣缴金额"
        />
      </el-table>
      <div slot="footer">
        <el-button @click="showProjectPayResultList = false">我知道了</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import paginationMixin from '@/mixins/pagination'
import {isMobile} from '@/utils/util.js'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'SubmitVideoList',
  mixins: [paginationMixin],
  data() {
    return {
      activeRadio: 'three_month',
      radioList: [
        { name: '近一周', key: 'week' },
        { name: '近一月', key: 'month' },
        { name: '近三月', key: 'three_month' }
      ],
      showBatchOperation: false,
      searchParams: {
        title: '',
        isQueryChild: false,
        startAt: `${this.$moment()
          .add(-3, 'month')
          .format('YYYY-MM-DD HH:mm:ss')}`,
        endAt: `${this.$moment().format('YYYY-MM-DD HH:mm:ss')}`
      },
      pagination: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        sizes: [10, 20, 50, 100],
        layout: isMobile() ? 'total, prev, pager, next' : 'total, sizes, prev, pager, next',
        small: true
      },
      createrList: [
        {
          id: false,
          label: '我创建的'
        },
        {
          id: true,
          label: '其他组织'
        }
      ],
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.searchParams.startAt = `${this.$moment(minDate).format('YYYY-MM-DD')} 00:00:00`
          this.searchParams.endAt = `${this.$moment(maxDate).format('YYYY-MM-DD')} 23:59:59`
        }
      },
      dataList: [],
      multipleSelection: {},
      isExport: false,
      isMobile: isMobile(),
      loading: false,
      isPadding: false,
      showProjectPayResultList: false,
      projectPayResultList: [],
      showOperation: true
    }
  },
  computed: {
    ...mapGetters(['factoryId'])
  },
  mounted() {
    this.getDataList()
    this.getRuler()
  },
  methods: {
    ...mapActions([
      'getOrgList',
      'getDoctorCertifyRule'
    ]),
    getRuler() {
      this.getDoctorCertifyRule({ uOrgId: this.factoryId }).then(res => {
        this.showOperation = res.isPreReimbursement === 0
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    onChangeRadio(val) {
      this.activeRadio = val
      switch (this.activeRadio) {
        case 'week':
          this.searchParams.startAt = this.$moment()
            .add(-1, 'weeks')
            .format('YYYY-MM-DD HH:mm:ss')
          this.searchParams.endAt = this.$moment()
            .add(0, 'days')
            .format('YYYY-MM-DD HH:mm:ss')
          break
        case 'month':
          this.searchParams.startAt = this.$moment()
            .add(-1, 'months')
            .format('YYYY-MM-DD HH:mm:ss')
          this.searchParams.endAt = this.$moment()
            .add(0, 'days')
            .format('YYYY-MM-DD HH:mm:ss')
          break
        case 'three_month':
          this.searchParams.startAt = this.$moment()
            .add(-3, 'months')
            .format('YYYY-MM-DD HH:mm:ss')
          this.searchParams.endAt = this.$moment()
            .add(0, 'days')
            .format('YYYY-MM-DD HH:mm:ss')
          break
      }
      this.pagination.pageNo = 1
      this.getDataList()
    },
    // 学术调研委托服务
    getDataList() {
      if (!this.$moment(this.searchParams.startAt).isValid()) return this.$message.error('请选择开始日期')
      if (!this.$moment(this.searchParams.endAt).isValid()) return this.$message.error('请选择结束日期')
      this.loading = true
      const params = {
        ...this.searchParams,
        pageNo: this.pagination.pageNo,
        pageSize: this.pagination.pageSize,
        startAt: `${this.$moment(this.searchParams.startAt).format('YYYY-MM-DD')} 00:00:00`,
        endAt: `${this.$moment(this.searchParams.endAt).format('YYYY-MM-DD')} 23:59:59`
      }
      this.$axios.get(this.$API.getReviewSuccessList, { params }).then(res => {
        this.loading = false
        this.pagination.total = res.data.totalCount
        this.dataList = res.data.data
      }).catch(rea => {
        this.loading = false
        this.$message.error(rea.message)
      })
    },
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    },
    onChangeDate(val) {
      if (val === null) {
        this.searchParams.startAt = this.$moment(this.searchParams.startAt).format('YYYY-MM-DD HH:mm:ss')
        this.searchParams.endAt = this.$moment(this.searchParams.endAt).format('YYYY-MM-DD HH:mm:ss')
      }
      this.activeRadio = ''
      this.onSearch()
    },
    onExport() {
      if (!this.$moment(this.searchParams.startAt).isValid()) return this.$message.error('请选择开始日期')
      if (!this.$moment(this.searchParams.endAt).isValid()) return this.$message.error('请选择结束日期')
      if (!this.dataList.length) return this.$message.error('暂无需要导出的数据')
      if (this.isExport) return
      this.isExport = true
      this.searchParams.startAt = `${this.$moment(this.searchParams.startAt).format('YYYY-MM-DD')} 00:00:00`
      this.searchParams.endAt = `${this.$moment(this.searchParams.endAt).format('YYYY-MM-DD')} 23:59:59`
      this.$axios.get(this.$API.exportReviewSuccessList, { params: this.searchParams }).then(res => {
        this.isExport = false
        this.$message.success(res.data)
      }, rea => {
        this.$message.error(rea.message)
      }).catch(() => {
        this.isExport = false
      })
    },
    onOperate(delegationId, result) {
      if (result === '1') {
        this.$confirm('请确认是否确认采购该委托服务包', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if (this.isPadding) return
          this.reviewDelegation(delegationId, result)
        })
      } else {
        this.$prompt('请输入拒绝原因', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /\S/,
          inputErrorMessage: '请输入拒绝原因'
        }).then(({ value }) => {
          this.reviewDelegation(delegationId, result, value)
        })
      }
    },
    reviewDelegation(delegationId, result, rejectReason = '') {
      this.isPadding = true
      this.$axios.get(this.$API.confirmPurchasLiveList, { params: { delegationId, result, rejectReason } }).then(res => {
        if (res.data.showErrorInfo) {
          this.showProjectPayResultList = res.data.showErrorInfo
          this.projectPayResultList = res.data.projectPayResultList
        } else {
          this.$message.success('操作成功')
        }
        this.isPadding = false
        this.getDataList()
      }, rea => {
        this.isPadding = false
        this.$message.error(rea.message)
      })
    },
    onBatchOperation() {
      this.showBatchOperation = !this.showBatchOperation
    },
    onBatchPurchase() {
      if (!this.multipleSelection.length) return this.$message.error('请选择需要采购的委托服务包')
      this.$confirm('请确认是否批量采购委托服务包', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const packageIdList = this.multipleSelection.map(item => item.delegationId)
        this.batchPurchase(packageIdList)
      })
    },
    batchPurchase(packageIdList) {
      this.$axios.post(this.$API.batchPurchasLiveList, { packageIdList }).then(() => {
        this.$message.success('操作成功')
        this.getDataList()
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    }
  }
}
</script>
<style lang="scss" scoped>
.action-fix {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
}
.reset-btn {
  margin-left: 10px;
}
.nav-wrapper {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    display: block;
  }
  .left {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .select {
      width: 120px;
      ::v-deep .el-select__tags {
        >span {
          width: 100%;
          display: grid;
          grid-template-columns: auto auto;
          >span {
            border: none;
            overflow: hidden;
            .el-tag__close.el-icon-close {
              background-color: transparent;
              &:hover {
                background-color: #c0c4cc;
                color: #909399;
              }
            }
          }
        }
      }
    }
    .input {
      width: 200px;
      ::v-deep .el-input__inner {
        background-color: #fafafa;
        border-radius: 20px;
      }
    }
  }
  .right {
    flex-shrink: 0;
    display: flex;
    align-items: flex-start;
    gap: 9px;
  }
}
</style>
